@import url('https://fonts.googleapis.com/css?family=Roboto:400,300');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

$font-family-base: 'Noto Sans JP', 'Roboto', sans-serif;
$font-weight-base: 400;
$font-size-base: 0.9rem;
$enable-rounded: false;
$navbar-height: 100px;
$navbar-height-mobile: 50px;
$navbar-extra-top-menu-height: 2rem;
$input-focus-bg: white;

$custom-colors: (
  // "primary": #577755,
  // "secondary": #696969,
  'primary': #d3222a,
  'secondary': #002432,
  'otto-olsen-white': #f7f5f5,
  'otto-olsen-white-alternative': #f7f5f0,
  'otto-olsen-grey': #696969,
  'otto-olsen-grey-light': #c4c2c2,
  'otto-olsen-grey-bright': #d4d4d4,
  'otto-olsen-secondary-light': #037389
);

$primary: #d3222a;
$secondary: #002432;

$gung-logo: false;
$gung-navbar-top-info-background-color: map-get($custom-colors, primary);
$primary: map-get($custom-colors, primary);

.badge {
  --bs-badge-color: #000 !important
}
