$theme-colors: map-merge($theme-colors, $custom-colors); 
.fixed-search {
  height: unset !important;
  position: sticky;
  top: 145px;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

otto-olsen-product-export-list .fixed-search,
otto-olsen-product-list .fixed-search {
  top: 105px;
}

.gung-stepper {
  top: 107px !important;
  position: sticky !important;
  padding-top: 5px;
  .stepper-control-top {
    width: 100%;
    margin-left: unset;
  }
}

.text-primary {
  color: map-get($theme-colors, otto-olsen-white);
}

.navbar {
  .nav-item {
    .dropdown-toggle {
      color: map-get($theme-colors, otto-olsen-grey) !important;
    }
  }

  .navbar-toggler {
    color: map-get($theme-colors, otto-olsen-grey);
  }

  lib-flow-select {
    .dropdown-toggle {
      color: map-get($theme-colors, otto-olsen-grey);
    }
  }

  lib-navbarcart {
    .cart-button {
      // color: map-get($theme-colors, otto-olsen-grey);
      color: black;
    }
  }
}

.gung-mega-top-menu-expanded {
  height: calc(100vh - 190px);
}

.modal-lg-custom .modal-lg {
  max-width: 1150px !important;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}

otto-olsen-home .carousel-indicators {
  display: none;
}

nav.navbar .navbar-top-info a.small {
  color: white !important;
}

.product-tabel-detail lib-gung-text-input-horizontal > div {
  border-bottom: solid 1px map-get($theme-colors, otto-olsen-grey-light);
  margin-right: 0;
  margin-left: 0;
}

.row-breadcrumb {
  top: 107px !important;
}

.margin-top-when-fixed-top-filters {
  margin-top: unset !important;
}

.accordion-product-details-concept h3 {
  color: map-get($theme-colors, primary);
}

.card-assortment-tree .card-body {
  background-color: map-get($theme-colors, otto-olsen-white-alternative);
}

otto-olsen-product-details label {
  text-transform: unset;
}

/* mfrsku - javascript embed */
.CspInlineContainer {
  background-color: #fff !important;
  font-family: arial;
  font-size: 12px;
  color: #353535;
}

.CspInlineContainer .placeholder{
  opacity: 1 !important;
  background-color: #fff !important;
  cursor: default !important;
}

.CspInlineContainer .tabs-menu li {
  background-color: map-get($theme-colors, secondary) !important;
}

.CspInlineContainer .tabs-menu li.current,
.CspInlineContainer .tabs-menu li:hover {
  background-color: map-get($theme-colors, otto-olsen-secondary-light) !important;
}

.CspInlineContainer .tab {
  border: 1px solid #f7f7f7 !important;
  background-color: #fff;
  margin-bottom: 20px;
  width: auto;
}

.CspInlineProductTitle {
  color: map-get($theme-colors, primary) !important;
}
.CspInlineContainer h1.CspInlineProductTitle {
  color: #333 !important;
}

.CspInlineContainer #tab-4 th.cad-asset-cell {
  background-color: map-get($theme-colors, secondary) !important;
}

/* StartPrint */

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
  html {
    border: 0px solid black !important;
    height: 99% !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  body {
    height: 99% !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .container {
    min-width: auto !important;
    width: 100%;
    max-width: none;
  }
  .container.main {
    padding: 0 !important;
    margin: 0 !important;
  }

  a.request-price-email,
  otto-olsen-root otto-olsen-navbar .cart-menu-wrapper,
  otto-olsen-root otto-olsen-navbar .menu-links,
  otto-olsen-root otto-olsen-assortment-tree-navigation .row-breadcrumb,
  otto-olsen-root lib-footer,
  otto-olsen-root lib-image-carousel .scolling-images,
  otto-olsen-root lib-image-carousel .fa-search-plus,
  otto-olsen-root otto-olsen-image-carousel .scolling-images,
  otto-olsen-root otto-olsen-image-carousel .fa-search-plus,
  otto-olsen-root otto-olsen-navbar div.d-flex.justify-content-end,
  otto-olsen-root lib-price,
  otto-olsen-footer {
    display: none !important;
  }

  otto-olsen-navbar {
    position: inherit !important;
    .gung-navbar {
      padding: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid map-get($theme-colors, primary);
      .container {
        padding: 0px;
        margin: 0px;
        min-width: auto !important;
      }
      .contacts {
        a, span {
          font-size: 12px;
        }
      }
      .navbar-brand {
        img {
          height: 16px;
        }
      }
    }
    .navbar-top {
      height: 40px !important;
      margin-bottom: 5px !important;
    }
  }

  mat-sidenav-container {
    margin-top: 0 !important;
  }

  otto-olsen-product-details lib-gung-text-input-horizontal label,
  otto-olsen-product-details lib-gung-text-input-horizontal input {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    line-height: inherit !important;
  }

  otto-olsen-product-details label.col-form-label {
    white-space: nowrap;
    display: inline-table;
  }

  @page:last {
    @bottom-center {width: 100%; content: element(footer-print); position: fixed;}
  }

  .footer-print {
    position: unset!important;
  }  
  
}

/* EndPrint */

lib-product-tree-table-view table tr td:nth-last-child(2),
lib-product-tree-table-view table tr th:nth-last-child(2) {
  text-align: right;
}

@media only screen and (max-width: 760px) {
  lib-product-tree-table-view table tr td:nth-last-child(2),
  lib-product-tree-table-view table tr th:nth-last-child(2) {
    text-align: unset;
  }
}

lib-product-pagination-list .progress {
  display: none;
}

lib-checkout-wrapper .form-group label,
lib-checkout-wrapper .custom-control label,
lib-checkout-wrapper h4 {
  text-transform: unset;
}

lib-checkout-wrapper lib-buttons {
  .btn-create-quotation {
    display: none !important;
  }
}

.product-card-button {
  button {
    font-size: 12px !important;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.wrapper-favourite {
  display: grid;
  grid-template-columns: 1fr 20px;
  .favourite-element {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

gung-jeeves-jeeves-customer-list-card .customer-name {
  color: map-get($theme-colors, primary) !important;
}

gung-jeeves-jeeves-customer-details .nav-tabs {
  .nav-link {
    /* border: none;
    color: map-get($theme-colors, primary);
    border-radius: 0;
    height: 2.5rem; */
    &:hover {
      color: map-get($theme-colors, secondary);
    }
  }
  .nav-link.active {
    color: map-get($theme-colors, primary);
    -webkit-box-shadow: 10px 0 5px -2px #888;
    box-shadow: 10px 0 10px -2px #888;
    border-radius: 0 5px 0 0;
  }
}

otto-olsen-account-requests-card-list .form-group {
  margin-bottom: 0;
}

lib-pagination-list .container {
  padding-top: 20px;
}

.thead-sticky {
  top: 165px;
}

// otto-olsen-product-pagination-list-card .thead-sticky{
//   top: 260px;
// }

@media screen and (max-width: 767px) {
  .thead-sticky {
    top: 160px;
    position: unset;
  }
}

lib-pagination-list {
  position: relative;

  .pagination-list-header {
    position: sticky;
    top: 168px;
    background-color: white;
    z-index: 99;
    padding-top: 10px;
  }
}

otto-olsen-availability {
  .warn {
    color: orange;
  }

  span.d-block {
    display: table !important;
  }
}

@media screen and (min-width: 1440px) {
  .navbar > .container {
    padding: 0 !important;
  }
  .container {
    max-width: 1920px !important;
  }
}

otto-olsen-product-list-assortment .fixed-search {
  max-width: 100% !important;
}

.buy-btn-width-table {
  otto-olsen-buy-btn {
    width: 100px;
  }
}

a.request-price-email button {
  width: 160px;
}

otto-olsen-create-product {
  label {
    text-transform: none !important;
  }
}

otto-olsen-order-terms-jeeves-with-cart,
otto-olsen-jeeves-sales-cart-list lib-delivery-date-picker input,
otto-olsen-sales-cart-list lib-delivery-date-picker input,
otto-olsen-jeeves-sales-cart-list otto-olsen-delivery-date-picker input,
otto-olsen-sales-cart-list otto-olsen-delivery-date-picker input,
otto-olsen-jeeves-sales-cart-list lib-delivery-date-picker-beta input,
otto-olsen-sales-cart-list lib-delivery-date-picker-beta input {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

ngb-modal-window > .modal-dialog > .modal-content {
  border: solid 3px map-get($theme-colors, primary);;
}


otto-olsen-order-terms-jeeves-with-cart,
otto-olsen-sales-cart-list, otto-olsen-jeeves-sales-cart-list{
  otto-olsen-buy-btn{
    display: inline-block !important;
    width: 130px;
    button{
      height: 35px;
      i{
        font-size: 18px !important;
      }

    }
    input{
      height: 35px !important;
      font-size: 16px !important;
      padding: 0px !important;
    }
  }
  lib-price{
    font-size: 16px;
  }
  otto-olsen-availability{
    font-size: 16px;
  }
  lib-delivery-date-picker,
  otto-olsen-delivery-date-picker,
  lib-delivery-date-picker-beta{
    .form-group {
      margin-bottom: 0!important;
    }
    .input-date{
      font-size: 16px;
      padding: 0px !important;
      text-align: center;
    }
    button{
      padding: 0px 3px !important;
      height: 38px;
      line-height: 0 !important;
      &.calendar{
        font-size: 2em;
      }
    }
  }
}

otto-olsen-scanner-search{
  otto-olsen-inline-search-navbar{
    .inline-search-container-mobile{
      position: relative !important;
      width: 100% !important;
    }
    
    .inline-search{
      display: flex !important;
      width: 100% !important;
    }
  }
}

otto-olsen-mobile-product-description{
  lib-gung-accordion{
    .card-header{
      button{
        width: 100%;
        text-align: left;
      }
    }
  }
}

.modal-lg-viewer-custom > .modal-lg {
  max-width: 95% !important;
}

.blur {
  color: transparent;
  text-shadow: 0 0 10px rgb(0 0 0 / 50%);
  visibility: hidden;
  font-size: 0;
}

.blur:after {
  content: '000.00';
  visibility: visible;
  font-size: 1rem;
}

.pim-edit {
  color: #0000FF;
}

ngx-scanner-qrcode {
  max-height: 250px !important;
  
  canvas {
    max-height: 250px !important;    
  }

  video {
    max-height: 250px !important; 
  }

  .origin-video {
    object-fit: cover !important;
  }
}

.text-info.border-info{
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

otto-olsen-block-order-agreement-create{
  lib-meta-select-input, lib-gung-date-picker, lib-gung-input-multi-select, lib-gung-select-option-input{
    .form-group{
      margin-bottom: 0px;
    }
  }
}